import * as React from 'react';
import { Link, PageProps } from 'gatsby';

import Seo from '../components/seo';
import Layout from '../components/layout';

const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="404 Not Found" />
      <h1>404 Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <p>
        <Link to="/">Go home</Link>
      </p>
    </Layout>
  );
};

export default NotFoundPage;
